import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, firstValueFrom } from 'rxjs';
import { ConversationControllerService, ConversationResponseDto, MessageDto } from '@brody-bookings/api-v2';
import { LanguageService } from '../../../../service/language.service';
import { MessageService, SelectItemGroup, TreeNode } from 'primeng/api';
import { AuthenticationService } from '../../../../../layout/service/app.auth.service';
import { CreateConversationRequestDto } from '@brody-bookings/api';
import { WidgetConversationService } from './_service/widget.conversation.service';
import { ConfirmModalService } from '../../../../service/confirmModal.service';


@Component({
    selector: 'app-conversation-widget',
    templateUrl: './conversation.widget.component.html',
    styleUrls: ['./conversation.widget.component.scss']
})
export class ConversationWidgetComponent implements OnInit, OnDestroy {

    private subscriptions: Subscription[] = [];
    projectParticipants: TreeNode[] = [];
    modelParticipants: TreeNode[] = [];
    selectedRecipient!: TreeNode<any> | TreeNode<any>[] | null;

    isBroadCast = false;
    selectedReceiverConversationId: string | undefined = undefined;
    messageList: MessageDto[] = [];

    currentUserOauthId = '';
    // Models

    constructor(
        private router: Router,
        private conversationControllerService: ConversationControllerService,
        private languageService: LanguageService,
        private authenticationService: AuthenticationService,
        private widgetConversationService: WidgetConversationService,
        private messageService: MessageService,
        private confirmModalService: ConfirmModalService
    ) {

    }

    ngOnInit(): void {
        this.loadModels();
        this.loadProjectRelevantPersons('433279b3-c778-4bb2-b7da-58af625d617d');
        this.initCurrentUser();

        this.messageService.add({
            severity: 'success',
            summary: 'Nachricht erfolgreich versendet',
            detail: 'Nachricht an alle Teilnehmer gesendet'
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    loadProjectRelevantPersons(projectId: string) {
        // Dummy data to simulate the participants
        const participants = {
            producer: [
                { participantId: '1', participantFirstName: 'John', participantLastName: 'Doe' }
            ],
            models: [
                {
                    role: 'Model Role 1',
                    models: [
                        { participantId: '2', participantFirstName: 'Jane', participantLastName: 'Smith' }
                    ]
                }
            ],
            additional: [
                { participantId: '3', participantFirstName: 'Alice', participantLastName: 'Johnson' }
            ],
            external: [
                { participantId: '4', participantFirstName: 'Bob', participantLastName: 'Brown' }
            ]
        };

        this.projectParticipants = [
            {
                key: '0',
                label: 'Executive',
                data: participants.producer?.map(producer => producer.participantId).join(','),
                icon: 'pi pi-fw pi-inbox',
                children: participants.producer?.map((producer) => {
                    return {
                        key: '0-' + producer.participantId,
                        label: producer.participantFirstName + ' ' + producer.participantLastName,
                        data: producer.participantId,
                        icon: 'pi pi-fw pi-cog'
                    };
                })
            },
            {
                key: '1',
                label: 'Models',
                data: participants.models?.map(projectModelByRole => projectModelByRole.models?.map(model => model.participantId)).join(','),
                icon: 'pi pi-fw pi-inbox',
                children: participants.models?.map((projectModelByRole, index) => {
                    return {
                        key: '1-' + index,
                        label: projectModelByRole.role,
                        data: projectModelByRole.models?.map(model => model.participantId).join(','),
                        icon: 'pi pi-fw pi-cog',
                        children: projectModelByRole.models?.map((model, _index) => {
                            return {
                                key: '1-1-' + _index + '-' + model.participantId,
                                label: model.participantFirstName + ' ' + model.participantLastName,
                                data: model.participantId,
                                icon: 'pi pi-fw pi-cog'
                            };
                        })
                    };
                })
            },
            {
                key: '2',
                label: 'Additional Participants',
                data: participants.additional?.map(additional => additional.participantId).join(','),
                icon: 'pi pi-fw pi-inbox',
                children: participants.additional?.map((additionalParticipant) => {
                    return {
                        key: '2-' + additionalParticipant.participantId,
                        label: additionalParticipant.participantFirstName + ' ' + additionalParticipant.participantLastName,
                        data: additionalParticipant.participantId,
                        icon: 'pi pi-fw pi-cog'
                    };
                })
            },
            {
                key: '3',
                label: 'External Participants',
                data: participants.external?.map(ext => ext.participantId).join(','),
                icon: 'pi pi-fw pi-inbox',
                children: participants.external?.map((externalParticipant) => {
                    return {
                        key: '3-' + externalParticipant.participantId,
                        label: externalParticipant.participantFirstName + ' ' + externalParticipant.participantLastName,
                        data: externalParticipant.participantId,
                        icon: 'pi pi-fw pi-cog'
                    };
                })
            }
        ];
    }

    loadModels() {
        const language = this.languageService.getLanguage();
        firstValueFrom(this.conversationControllerService.getModels1(language)).then((participants) => {
            this.modelParticipants = participants.myModels?.map((model) => {
                return {
                    key: '0-' + model.participantId,
                    label: model.participantFirstName + ' ' + model.participantLastName,
                    data: model.participantId,
                    icon: 'pi pi-fw pi-cog'
                };
            }) || [];
        });
    }


    nodeSelect(_: any) {
        const { data } = this.selectedRecipient as TreeNode<any>;
        this.isBroadCast = (this.selectedRecipient as any)?.parent === undefined;

        if (!this.isBroadCast) {
            this.loadMessagesForConversation(data);
        } else {
            this.resetMessages();
        }
    }


    onSendMessage(newMessage: string, images: Array<Blob>) {
        const { data } = this.selectedRecipient as TreeNode<any>;

        if (this.isBroadCast) {
            return this.sendBroadCastMessage(data, newMessage, images);
        } else {
            return this.sendDirectMessage(data, newMessage, images);
        }

    }


    onDeleteMessage(messageId: string) {
        if (!this.selectedReceiverConversationId) return;

        const conversationId = this.selectedReceiverConversationId;
        this.confirmModalService.showConfirmModal('Willst du diese Nachricht wirklich löschen?', () => {
            this.widgetConversationService.deleteMessage(conversationId, messageId).then(() => {
                this.messageList = this.messageList.filter(m => m.messageId !== messageId);
            });
        });
    }

    onRefreshMessages() {
        if (!this.selectedReceiverConversationId) return;

        const conversationId = this.selectedReceiverConversationId;
        this.widgetConversationService.refreshMessages(conversationId).then((messages) => {
            this.messageList = messages;
        })
    }

    onCameraButtonClick() {
        console.log('hallo');
    }

    private initCurrentUser() {
        this.subscriptions.push(this.authenticationService.frontendUser$.subscribe((currentUser) => {
            if (!currentUser) return;
            this.currentUserOauthId = currentUser.userId;
        }));
    }

    private loadMessagesForConversation(oauthId: any) {
        firstValueFrom(this.conversationControllerService.getConversationOfParticipants({
            senderId: this.currentUserOauthId,
            participantIds: [oauthId]
        })).then((conversation: ConversationResponseDto) => {
            this.selectedReceiverConversationId = conversation.conversationId;
            this.messageList = (conversation.messages || []).map(m => {
                return {
                    messageId: m.messageId,
                    senderId: m.senderId,
                    senderName: m.senderName,
                    message: m.message,
                    imageUrl: m.imageUrl,
                    videoUrl: m.videoUrl,
                    timeStamp: m.timeStamp,
                    formattedTimeStamp: m.formattedTimeStamp
                };
            });
        }).catch((error) => {
            if (error.status === 404) {
                this.messageList = [];
            }
        });
    }

    private resetMessages() {
        this.messageList = [];
        this.selectedReceiverConversationId = undefined;
    }

    private async sendDirectMessage(oauthId: string, newMessage: string, images: Array<Blob>) {
        return this.widgetConversationService.sendMessage(oauthId, newMessage, false, images).then((response) => {
            response.messages?.forEach((message) => {
                this.messageList.push(message);
            });
        });
    }

    private sendBroadCastMessage(oauthIds: string, newMessage: string, images: Array<Blob>) {
        return this.widgetConversationService.sendMessage(oauthIds, newMessage, true, []).then((response) => {
            response.messages?.forEach((message) => {
                this.messageList.push(message);
            });

            this.messageService.add({
                severity: 'success',
                summary: 'Nachricht erfolgreich versendet',
                detail: 'Nachricht an alle Teilnehmer gesendet'
            });
        });
    }


}