<div class="flex">
    <div class="container-list">
        <div class="headline">
            <h3 class="text-lg font-bold">Direktnachrichten</h3>
        </div>
        <ng-container *ngIf="!modelParticipants.length">
            <div class="skeleton-loader">
                <!-- Add your skeleton loader HTML here -->
                <p-skeleton width="100%" height="6rem"></p-skeleton>
            </div>
        </ng-container>
        <ng-container *ngIf="modelParticipants.length">
            <div class="tree-container">
                <p-tree [value]="modelParticipants" class="w-full md:w-35rem" selectionMode="single" [filter]="true"
                    filterPlaceholder="Models / Dienstleister / Mitarbeiter" [(selection)]="selectedRecipient"
                    (onNodeSelect)="nodeSelect($event)"></p-tree>
            </div>
        </ng-container>
        <ng-container *ngIf="projectParticipants.length">
            <div class="mt-4">

                <div class="headline">
                    <h3 class="text-lg font-bold">Projektnachrichten</h3>
                </div>
                <div class="tree-container">
                    <p-tree [value]="projectParticipants" class="w-full md:w-35rem" selectionMode="single"
                        [(selection)]="selectedRecipient" (onNodeSelect)="nodeSelect($event)"></p-tree>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="container-chat">
        <app-conversation-chat [isVisible]="selectedRecipient !== null"
            [conversationId]="selectedReceiverConversationId" [messageList]="messageList"
            [currentUserOauthId]="currentUserOauthId" [isGroupChat]="false" [shouldScrollToBottom]="true"
            [onSendMessage]="onSendMessage.bind(this)" [onDeleteMessage]="onDeleteMessage.bind(this)"
            [onCameraButtonClick]="onCameraButtonClick.bind(this)"
            [onRefreshMessages]="onRefreshMessages.bind(this)"></app-conversation-chat>
    </div>
</div>
<p-toast></p-toast>