import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { LanguageService } from '../demo/service/language.service';
import { AuthenticationService } from "./service/app.auth.service"
import { Subscription } from 'rxjs';
import {
    ConversationWidgetComponent
} from '../demo/components/shared/widgets/conversation/conversation.widget.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit, OnDestroy {

    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;
    name = '';
    subscriptions: Subscription[] = []
    numberOfNewMessages: string | undefined = undefined;

    constructor(
        public layoutService: LayoutService,
        private languageService: LanguageService,
        private dialogService: DialogService,
        public auth:AuthenticationService,
        private router: Router
    ) {
        this.subscriptions.push(this.auth.frontendUser$.subscribe((user) => {
            if (user?.userFirstName && user?.userLastName) {
                this.name = user.userFirstName + " " + user.userLastName
            }
        }))
    }

    ngOnInit(): void {
        // Removed model search initialization
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    toGerman($event: MouseEvent) {
        $event.preventDefault();
        this.languageService.setLanguage('de')
    }

    toEnglish($event: MouseEvent) {
        $event.preventDefault();
        this.languageService.setLanguage('en')
    }

    handleLogout($event: MouseEvent): void {
        $event.preventDefault();
        this.auth.handleLogout();
    }

    openMessages() {
        this.numberOfNewMessages = undefined;
        this.dialogService.open(ConversationWidgetComponent, {
            header: "Nachrichten",
            contentStyle: { overflow: 'auto' },
            width: '40vw',
            baseZIndex: 10000,
            modal: false,
            maximizable: true,
            draggable: true,
            resizable: true
        })
    }
}
